import React, { lazy, Suspense, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isPermitted } from "../utils/PermissionManager";
import { Empty } from "antd";
const MainContent = lazy(() => import("../layout/MainContent"));

export default function AdminRoute(props) {
  const roles = useSelector((state) => state.roles?.roles);
  const activated = useSelector((state) => state.auth.activated);
  // console.log('roles', roles)
  const { show, trigger } = props.state;
  const { permission } = props;
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  const auth = useSelector((state) => state.auth);
  // && isPermitted(roles, permission)
  if (auth.isAuthenticated) {
    // console.log('true')
    let content = (
      <>
        <Suspense fallback={<div className="pageProgress"></div>}>
          <MainContent state={{ show, trigger }}>{props.component}</MainContent>
        </Suspense>
      </>
    );
    return content;
  } else if (auth.isAuthenticated && !isPermitted(roles, props.permission)) {
    // console.log("false");
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Empty description="404 Not Found" />
      </div>
    );
  } else {
    return <Navigate to={"/login"} />;
  }
}
