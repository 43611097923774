import moment from "moment";

export const siteInfo = {
  name: "NameChip",
  navColor: "#D1412A",
  secondary: "#41BCE1",
  primary_light_bg: "#E9EFF0",
  asideColor: "#fff",
  payable: 0.5,
  minimum_price: 1000,
  minimum_quantity: 3,
  error: "#C4161C",
  tableHeader: "#E9F0EE",
  activationTime: 600,
  isActivated: () => {
    let activated = false;

    let closed = localStorage.activated;
    let dateA = moment(new Date());
    let dateB = moment(new Date(closed));
    let sec = dateA.diff(dateB, "seconds");

    if (sec < siteInfo.activationTime) {
      activated = true;
    }

    return activated;
  },
  by_air: {
    a: 630,
    b: 750,
  },
};
