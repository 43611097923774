import { Layout, Menu, notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SideMenu from "./SideMenu";
import { useSelector } from "react-redux";
//   import { sidebarItems } from "../utils/Sidebars";
const { Sider } = Layout;

export default function Sidebar(props) {
  const location = useLocation();
  const { show, setshow } = props.state;

  let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <div
      style={{
        display: isAuthenticated ? "block" : "none",
      }}
    >
      {location.pathname !== "/login" &&
        !location.pathname.includes("public") && (
          <Sider
            trigger={null}
            breakpoint="xs"
            onBreakpoint={(broken) => {
              setshow(broken);
            }}
            width="240px"
            style={{
              overflow: "auto",
              position: "fixed",
              zIndex: 1,
              left: 0,
              bottom: 0,
              top: 0,
              paddingTop: "0.5rem",
              backgroundColor: "white",
              fontWeight: "bold",
              borderRight: "1px solid rgba(0,0,0,.1)",
              width: "240px",
            }}
            collapsedWidth="0"
            collapsed={show}
          >
            <a className="flex align-center justify-center p1" href="/">
              <img
                style={{ width: "148px" }}
                src={require("../assets/logo.svg").default}
                alt=""
              />
            </a>
            <SideMenu />
          </Sider>
        )}
    </div>
  );
}
